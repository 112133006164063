// UBUNTU

@font-face {
  font-family: 'Ubuntu';
  src: url('../../assets/fonts/ubuntu/Ubuntu-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Ubuntu';
  src: url('../../assets/fonts/ubuntu/Ubuntu-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Ubuntu';
  src: url('../../assets/fonts/ubuntu/Ubuntu-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Ubuntu';
  src: url('../../assets/fonts/ubuntu/Ubuntu-Bold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Ubuntu';
  src: url('../../assets/fonts/ubuntu/Ubuntu-LightItalic.ttf')
    format('truetype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Ubuntu';
  src: url('../../assets/fonts/ubuntu/Ubuntu-RegularItalic.ttf')
    format('truetype');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Ubuntu';
  src: url('../../assets/fonts/ubuntu/Ubuntu-MediumItalic.ttf')
    format('truetype');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Ubuntu';
  src: url('../../assets/fonts/ubuntu/Ubuntu-BoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

//HALVETICA NEU

@font-face {
  font-family: 'HalveticaNeu';
  src: url('../../assets/fonts/halvetica-neu/HelveticaNeue-Light.ttf')
    format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'HalveticaNeu';
  src: url('../../assets/fonts/halvetica-neu/HelveticaNeue-Regular.ttf')
    format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'HalveticaNeu';
  src: url('../../assets/fonts/halvetica-neu/HelveticaNeue-Medium.ttf')
    format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'HalveticaNeu';
  src: url('../../assets/fonts/halvetica-neu/Helvetica-Neu-Bold.ttf')
    format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Outfit';
  src: url(../../assets/fonts/Outfit/Outfit-Regular.ttf);
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Outfit';
  src: url(../../assets/fonts/Outfit/Outfit-Medium.ttf);
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Outfit';
  src: url(../../assets/fonts/Outfit/Outfit-SemiBold.ttf);
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Outfit';
  src: url(../../assets/fonts/Outfit/Outfit-Bold.ttf);
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url(../../assets/fonts/Poppins/Poppins-Light.ttf);
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Poppins';
  src: url(../../assets/fonts/Poppins/Poppins-Regular.ttf);
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Poppins';
  src: url(../../assets/fonts/Poppins/Poppins-Medium.ttf);
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Poppins';
  src: url(../../assets/fonts/Poppins/Poppins-SemiBold.ttf);
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
