.container {
  background-color: white;
  border-radius: 12px;
  padding: 16px;
  margin-top: 10px;
}

.flexContainer {
  display: flex;
  gap: 20px;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }
}

.flexItem {
  // width: 100%;
  flex: 1;
  overflow-x: auto;
}

.tableLight {
  background-color: #ffffff;
}
.tableDark {
  background-color: #fafafa;
}

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;

  @media screen and (max-width: 700px) {
    flex-direction: column;
    align-items: start;
    gap: 6px;
  }

  .propertyWrapper {
    width: 346px;
    max-width: 346px;

    @media screen and (max-width: 700px) {
      width: 100%;
    }
  }

  .bedroomContainer {
    .bedroomWrapper {
      display: flex;
      gap: 6px;

      @media screen and (max-width: 380px) {
        flex-wrap: wrap;
      }

      .bedrooms {
        cursor: pointer;
        background-color: white;
        display: flex;
        color: #8390a2;
        justify-content: center;
        align-items: center;
        border: 1px solid #cfd4db;
        border-radius: 8px;
        padding: 6px 20px;
        font-size: 14px;
        font-weight: normal;

        @media screen and (max-width: 380px) {
          padding: 5px 15px;
        }
      }

      .selected {
        background-color: #4361f9;
        color: white;
      }
    }
  }
}
