.lastUpdated {
  font-size: small;
  margin-block: 12px;
  color: grey;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;

  span {
    font-weight: 600;
    color: black;

    span.val {
      font-weight: 400;
    }
  }
}

.container {
  background-color: white;
  border-radius: 12px;
  padding: 32px;

  .title {
    margin: 0;
  }
}
