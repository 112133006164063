.forecastWrapper {
  --tableHeaderBgColor: #cfd4db;
  --tableHeaderTextColor: #2a313a;
  --tableTextColor: #616b79;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;

  .forecastContainer {
    --cardWidth: 1100px;
    --cardHeight: 506px;
    width: var(--cardWidth);
    min-width: var(--cardWidth);
    height: var(--cardHeight);
    padding: 1.5rem 0 0.5rem;
    color: rgba(0, 0, 0, 0.88);
    background-color: var(--tableHeaderBgColor);
    border-radius: 0.5rem;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.03), 0 1px 6px -1px rgba(0, 0, 0, 0.02),
      0 2px 4px 0 rgba(0, 0, 0, 0.02);
  }

  .loader {
    position: absolute;
    top: 1rem;
    left: 50%;
    z-index: 2000;
  }

  .loading {
    z-index: 20;
  }

  .titleWrapper {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .title {
    padding-left: 1.5rem;
    font-family: "Poppins";
    font-size: 1.1rem;
    font-weight: 600;
    color: var(--tableTextColor);
  }

  .columnChart {
    --chartWidth: 200px;

    .apexcharts-column-chart-1 {
      width: var(--chartWidth) !important;
    }

    svg {
      width: var(--chartWidth) !important;
    }
  }

  #chart-wrapper-forecast {
    .apexcharts-xaxis-texts-g {
      transform: translateY(6px) !important;
    }
  }

  .chartTooltip {
    color: #fff;
    font-size: 0.75rem;
    font-weight: 400;
    padding: 0.5rem;
    text-transform: capitalize;
    text-align: center;

    &[data-type="budget"] {
      --indigo500: #4361f9;
      background: var(--indigo500);
      border: 1px solid var(--indigo500);
    }

    &[data-type="actual"] {
      --orange500: #ffb84c;
      background: var(--orange500);
      border: 1px solid var(--orange500);
    }

    &[data-type="occupancy"] {
      --purple300: #a872fc;
      background: var(--purple300);
      border: 1px solid var(--purple300);
    }
    &[data-type="forecast"] {
      --grey700: #8390a2;
      background: var(--grey700);
      border: 1px solid var(--grey700);
    }
    &[data-type="leased"] {
      --green500: #00e396;
      background: var(--green500);
      border: 1px solid var(--green500);
    }

    .toolTipLabel {
      margin-bottom: 0.25rem;
      border-bottom: 1px solid #fff;
    }
  }
}
