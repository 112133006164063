.container {
  background-color: white;
  border-radius: 12px;
  padding: 16px;
  margin-top: 10px;
}

.flex {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.borderBottom {
  width: 90%;
  height: 0.5px;
  background: #2d364d;
  margin: auto;

  @media screen and (max-width: 700px) {
    display: none;
  }
}

.chartContainer {
  padding: 20px 0;
}

.borderLeft {
  border-left: 1px solid #2d364d;
  height: fit-content;

  @media screen and (max-width: 700px) {
    border-left: none;
  }
}

// fee charts css
.chartRowContainer {
  display: flex;
  flex-direction: row-reverse;
  max-height: 400px;
  overflow-x: hidden;
  white-space: nowrap;
  direction: rtl;
  margin-left: 30px;
  position: relative;

  @media screen and (max-width: 700px) {
    flex-direction: column;
  }
}

.chartColumn {
  flex: 1;
  display: inline-block;
  direction: ltr;
  padding-top: 10px;
  padding-bottom: 10px;
}

.lastRow {
  padding-top: 30px;
}

.chartColumn:last-child {
  margin-right: 0;
}

/* Style the scrollbar */
.chartRowContainer::-webkit-scrollbar {
  width: 4px; /* Width of the scrollbar */
  height: 10px;
}

/* Track */
.chartRowContainer::-webkit-scrollbar-track {
  background: #f5f6f6; /* Background color of the scrollbar track */
}

/* Handle */
.chartRowContainer::-webkit-scrollbar-thumb {
  background: #cfd4db; /* Color of the scrollbar handle */
  border-radius: 4px; /* Border radius of the scrollbar handle */
}

/* Handle on hover */
.chartRowContainer::-webkit-scrollbar-thumb:hover {
  background: #888; /* Color of the scrollbar handle on hover */
}

.yLabel {
  position: absolute;
  transform: rotate(-90deg);
  color: #8390a2;
  font-weight: normal;
  font-size: 18px;
  top: 50%;
  left: -10px;
}

.xLabel {
  position: absolute;
  color: #8390a2;
  font-weight: normal;
  font-size: 18px;
  left: 50%;
  bottom: 10px;
}
