.btnsContainer {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
  flex-wrap: wrap;

  .select {
    min-width: 100px;
  }

  @media screen and (max-width: 500px) {
    flex-direction: column;
    align-items: start;

    .select,
    .datePicker {
      width: 100%;
    }
  }
}

.datePicker {
  width: 300px;
}

.evenRows {
  background-color: #fafafa !important;
}

.container {
  background-color: white;
  border-radius: 12px;
  padding: 32px;
}
