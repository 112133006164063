.container {
  background-color: white;
  border-radius: 12px;
  padding: 16px;
  margin-top: 10px;
}

.tableLight {
  background-color: #fafafa;
}
.tableDark {
  background-color: #F5F6F6;
}

.header {
  display: flex;
  justify-content: space-between;
  gap: 10rem;
  margin-bottom: 2rem;
  background: white;
  padding: 1rem 10px;
  border-radius: 6px;

  @media (max-width: 1200px) {
    flex-direction: column;
    gap: 1rem;
  }

  .first_header_section {
    display: flex;
    flex: 1;
    gap: 1rem;
    .client_select {
        width: 100%;
    }
    .client_btn {
      display: flex;
      align-items: center;
      gap: 1rem;
    }
    .property_select {
        width: 100%;
    }
    .active_select {
        width: 100%;
    }

    @media (max-width: 576px) {
      flex-direction: column;
      gap: 1rem;
    }
  }
  .second_header_section {
  }
}
