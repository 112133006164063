.container {
  display: flex;

  .content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    overflow: auto;
  }

  .main {
    flex: 1;
    padding: 20px;
    overflow: auto;
    background-color: #f4f4f4;
  }
}
