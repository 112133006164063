.dashboard {
  padding-bottom: 20px;
  max-width: 1500px;
  margin-top: 20px;

  .heading {
    margin-bottom: 1rem;
    font-family: 'Poppins';
    font-size: 1.8rem;
    font-weight: 600;
    letter-spacing: 1px;
    color: #2c313a;
  }

  .sectionHeading {
    margin: 1rem 0;
    font-family: 'Poppins';
    font-size: 1.2rem;
    font-weight: 600;
    letter-spacing: 1px;
    color: #2c313a;
  }

  .filters {
    display: flex;
    gap: 1rem;
    margin-bottom: 0.8rem;
  }

  .topSection {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: space-between;
  }

  .bottomSection {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    justify-content: space-between;
  }
}
