.container {
  background-color: white;
  border-radius: 12px;
  padding: 16px;
  margin-top: 10px;
}

.tableLight {
  background-color: #fafafa;
}
.tableDark {
  background-color: #f5f6f6;
}
