.container {
  --mainBgColor: #f4f4f4;
  --labelTextColor: #2c313a;
  --tableHeaderBgColor: #cfd4db;
  --tableHeaderTextColor: #2a313a;
  --tableTextColor: #616b79;
  --tableDarkBgColor: #eff1f0;
  --tableLightBgColor: #fff;

  display: flex;

  .content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    overflow: auto;
  }

  .main {
    position: relative;
    flex: 1;
    padding: 0.5rem 1.25rem 1.25rem;
    overflow: auto;
    background-color: var(--mainBgColor);
  }
}
