.sectionHeader {
  display: flex;
  align-items: center;
  gap: 20px;
  margin: 20px 0;

  @media only screen and (max-width: 500px) {
    flex-direction: column;
    align-items: start;
    gap: 10px;
  }
  .filterContainer {
    display: flex;
    gap: 20px;

    @media only screen and (max-width: 767px) {
      gap: 10px;
      flex-direction: column;
    }

    .checkfilter{
      display: flex;
      align-items: center;
      @media only screen and (max-width: 767px) {
      }
    }
   
  }
}
.container {
  background-color: white;
  border-radius: 12px;
  padding: 16px;
  margin-top: 10px;

  .cardContainer {
    display: flex;
    border-radius: 12px;
    flex-direction: column;

  }

  .flexContainer {
    display: flex;
    gap: 20px;

    @media only screen and (max-width: 1090px) {
      flex-direction: column;
    }

    .halfRows {
      background-color: #f5f6f6;
      padding: 15px;
      border-radius: 12px;
      width: 100%;

      .contentContainer {
        display: flex;
        flex-direction: column;
        gap: 15px;

        .value {
          color: #4361f9;
          font-weight: 600;
          font-size: 28px;
        }
        .progressTrail {
          background-color: #ffffff;
          width: 100%;
          height: 30px;
          border-radius: 12px;
          display: flex;
          justify-content: start;
          align-items: center;
          padding: 0 4px;

          .progressStroke {
            background-color: #ffb84c;
            height: 22px;
            border-radius: 12px;
          }
        }
      }

      .cardContainer {
        display: flex;
        gap: 20px;
        flex-direction: row;
        @media only screen and (max-width: 600px) {
          flex-direction: column;
        }
      }

      @media only screen and (max-width: 1090px) {
        width: inherit;
      }
    }
  }
}

.tableLight {
  background-color: #ffffff;
}
.tableDark {
  background-color: #fafafa;
}
