.card {
  padding: 15px;
  padding-top: 0;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 10px;

  @media only screen and (max-width: 1150px) {
    gap: 20px;
  }

  @media only screen and (max-width: 960px) {
    flex-direction: column;
    align-items: start;
  }

  .imgContainer {
    padding: 20px;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    margin-right: 20px;
    display: flex;
    justify-content: center;

    img{
      width: 25px;
      height: 25px;
    }

    @media only screen and (max-width: 1150px) {
      margin: 0;
    }

    @media only screen and (max-width: 600px) {
      margin-right: 10px;
    }
  }

  .contentContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;

    @media only screen and (max-width: 1300px) {
      justify-content: start;
      align-items: start;
      flex-direction: column;
      gap: 10px;
    }

    @media only screen and (max-width: 600px) {
      justify-content: start;
      align-items: start;
    }

    p {
      color: #8390a2;
      font-size: 16px;
      font-weight: 400;
      margin: 0;

      @media only screen and (max-width: 1150px) {
        // text-align: center;
      }
    }

    .value {
      color: #4361f9;
      font-weight: 600;
    }

    .unit {
      color: #8390a2;
      font-size: 14px;
      font-weight: 400;
      margin-left: 6px;
    }
  }
}
