#goals-page .ant-input-number {
  margin-left: 0.4rem;
  width: unset !important;
}
#tourConversionPct .ant-input-number,
#netLeasesPct .ant-input-number,
#renewalPct .ant-input-number {
  width: 8rem !important;
}
#tourConversionPct .ant-input-number-input-wrap,
#netLeasesPct .ant-input-number-input-wrap,
#renewalPct .ant-input-number-input-wrap {
  padding-right: 0;
}
#goals-page .ant-input-number-input-wrap {
  padding-right: 0.4rem;
}

#goals-page .ant-slider {
  margin: 0;
}
#goals-page .ant-slider .ant-slider-rail {
  background: var(--tableHeaderBgColor);
}

#goals-page .ant-select-arrow {
  color: var(--labelTextColor) !important;
}

#goals-page .ant-select-selection-item {
  color: var(--labelTextColor) !important;
}
