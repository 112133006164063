.goals {
  position: relative;
  padding-bottom: 20px;

  .heading {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
  }

  .headingTitle {
    font-family: 'Poppins';
    font-size: 1.8rem;
    font-weight: 500;
    letter-spacing: 1px;
    color: var(--labelTextColor);
  }

  .filters {
    display: flex;
    gap: 1rem;
    margin-bottom: 0.8rem;
  }

  .loader {
    margin-left: 0.5rem;
  }

  .section {
    position: relative;
    margin-bottom: 1rem;
  }

  .sectionHeading {
    margin-bottom: 0.5rem;
    font-family: 'Poppins';
    font-size: 1.25rem;
    font-weight: 400;
    color: var(--labelTextColor);
  }
}
