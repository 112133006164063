.sidebar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // width: 240px;
  height: 100vh;
  transition: left 0.3s ease;
  background: #323a46;
  font-family: 'Outfit';

  .logo {
    width: 173px;
    margin-left: 1.45rem;
    margin-top: 1rem;
    img {
      width: 100%;
      object-fit: cover;
      height: 100%;
      cursor: pointer;
    }
  }
  .logoClosed {
    height: 46.4px;
    margin-left: 1.45rem;
    margin-top: 1rem;
    img {
      width: 39px;
      height: 39px;
      object-fit: cover;
      cursor: pointer;
      transform: translateY(2px);
    }
  }
  &.open {
    left: -240px;
  }

  .hamburger {
    background-color: blue;
    background: blue;
    display: none;
  }

  .sidebar_content {
    #toggle-sidebar {
      margin-left: auto;
    }
    .menu {
      list-style: none;
      padding-left: 0;
      .menuHeading {
        color: #ffffff;
        text-align: center;
      }

      .menuItem {
        display: flex;
        position: relative;
        width: calc(100% - 4px);
        height: 30px;
        margin-bottom: 10px;
        padding: 10px 0;
        color: #767676;
        border-radius: 5px;
        align-items: center;
        cursor: pointer;

        &:hover {
          .menuItemImg,
          .homeImg,
          .optipricer {
            filter: brightness(0) invert(1) !important ;
          }
          .prospectImg,
          .webImg,
          .admin {
            filter: brightness(0) invert(1) !important ;
            // opacity: 0.6;
          }

          border-radius: 0;
          background-color: #9f9f9f78;
          opacity: 1;
          a {
            color: #ffffff96;
          }

          .menuHover {
            display: block;
          }
        }
        a {
          color: #767676;
          text-decoration: none;
        }
        img {
          margin: 0 2rem;
          filter: brightness(0);
          width: 20px;
          height: 20px;
          vertical-align: middle;
          &:hover {
            color: #ffffff96;
            filter: brightness(0) invert(1) !important ;
          }
        }
      }
      .menuHover {
        position: absolute;
        border-right: 6px solid #3aa4f6;
        height: 50px;
        top: 0;
        right: -4px;
        border-radius: 0px;
        display: none;
      }
    }
    .activeItem {
      color: #fff !important;
      a {
        color: #fff !important;
        text-decoration: none;
      }
      img {
        color: #fff;
        filter: brightness(0) invert(1) !important ;
      }
    }
  }
  .user {
    padding: 10px 0;
    .toggleContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 1rem;
    }
    .divider {
      height: 1px;
      width: 80%;
      background: #adadad;
      margin: auto;
    }
    .main {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      margin: 0;
      padding-left: 20px;
      padding-right: 10px;
      cursor: pointer;

      & > div {
        display: flex;
        gap: 13px;
        margin: 14px 0;
      }
      .avatarMain {
        div {
          width: 35px;
          height: 35px;
          border-radius: 50px;
          background-color: #d9d9d9;
          display: flex;
          justify-content: center;
          align-items: center;
          div {
            font-size: 24px;
            font-weight: 500;
          }
        }
      }
      .dropArrowIcon {
        svg {
          font-size: 21px;
          color: white;
          transform: rotate(180deg);
        }
      }
      .namesMain {
        display: flex;
        align-items: center;
        gap: 13px;

        h6 {
          color: #ffffff;
          margin: 0;
          transform: translateY(200px);
          transition: all 0.3s ease-in;
        }

        h6[data-show='true'] {
          font-size: 16px;
          transform: translateX(0);
        }
      }
      .popup_main {
        position: absolute;
        background: white;
        width: 150px;
        height: auto;
        bottom: 27px;
        // left: 213px;
        text-align: center;
        border-radius: 3px;
        font-size: 17px;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
        padding: 13px 0;
        flex-direction: column;
        z-index: 999;
        p {
          padding: 6px 16px;
          margin: 0;
          display: flex;
          align-items: center;
          gap: 15px;
          span {
            font-size: 18px;
          }
          svg {
            font-size: 18px;
          }
        }
        p:hover {
          background-color: rgba(0, 0, 0, 0.04);
        }
      }
    }
  }
  .sidebarMenu {
    max-height: 75vh;
    overflow-y: auto;
  }

  @media (max-width: 767px) {
    width: 270px;
    height: auto;
    position: fixed;
    top: 0;
    left: -100%;
    background-color: #323a46;
    z-index: 1000;
    min-height: 100vh;

    &.open {
      left: 0;
    }

    .hamburger {
      background-color: transparent;
      background: transparent;
      display: block;
      position: fixed;
      top: 10px;
      left: 12px;
      padding: 8px;
      cursor: pointer;
      border: none;

      &:focus {
        outline: none;
      }

      .hamburgerBox {
        width: 25px;
        height: 3px;
        display: inline-block;
        position: relative;

        &::before,
        &::after,
        & .hamburgerInner {
          background-color: #767676;
          position: absolute;
          content: '';
          display: block;
        }

        &::before,
        &::after {
          width: 100%;
          height: 3px;
        }

        &::before {
          top: -8px;
        }

        &::after {
          bottom: -8px;
        }

        & .hamburgerInner {
          width: 100%;
          height: 3px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
    .close {
      position: absolute;
      right: 32px;
      top: 15px;
      width: 32px;
      height: 32px;
      opacity: 0.4;
      &:hover {
        opacity: 1;
        cursor: pointer;
      }
      &::before,
      &::after {
        position: absolute;
        top: -2px;
        left: 13px;
        content: ' ';
        height: 33px;
        width: 2px;
        background-color: #333;
      }
      &::before {
        transform: rotate(45deg);
      }
      &::after {
        transform: rotate(-45deg);
      }
    }

    .menu {
      margin-top: 10px;
    }
    .logo {
      margin: 1rem 0px 2rem 1rem !important;
    }
    .menuItem {
      margin-left: 0 !important;
    }
    .menuHeading {
      text-align: left !important;
    }
  }
}
