.headContainer{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;

  @media screen and (max-width: 500px) {
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap: 10px;
  }
}

.container {
  background-color: white;
  border-radius: 12px;
  padding: 16px;
  margin-top: 10px;
}

.tableLight {
  background-color: #fafafa;
}

.tableDark {
  background-color: #F5F6F6;
}

.pagination {
  display: flex;
  justify-content: end;
  margin-top: 10px;
}

.pagination a {
  color: black;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  transition: background-color 0.3s;
  border: 1px solid #ddd;
  margin: 0 4px;
  &.active {
    background-color: #69aae7;
    color: white;
    border: 1px solid #69aae7;
  }
  &:hover:not(.active) {
    background-color: #ddd;
  }
}
.container::-webkit-scrollbar {
  width: 10px;
}
.container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 3px grey;
  border-radius: 10px;
}

.container::-webkit-scrollbar-thumb {
  background: #69aae7;
  height: 100px;
  border-radius: 30px;
}

.container::-webkit-scrollbar-thumb:hover {
  background: #6992e7;
  cursor: pointer;
}
