.goalsForm {
  width: 1400px;
  min-width: 1400px;
  border: 1px solid var(--tableHeaderBgColor);
  border-radius: 0.5rem;

  .row {
    display: flex;
    margin: 0 !important;
    padding: 0.5rem 1rem;
    align-items: baseline;
    color: var(--tableTextColor);
    background-color: var(--tableLightBgColor);

    &[data-type="tours"] {
      flex-direction: column;
      gap: 0.75rem;
      padding-bottom: 1rem;
    }
  }
  .row[data-row='header'] {
    padding-top: 1rem;
    padding-bottom: 1rem;
    background-color: var(--tableHeaderBgColor);
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }
  .row[data-row='bottom'] {
    padding: 1rem;
    background-color: var(--tableHeaderBgColor);
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }
  .row[data-row='odd'] {
    background-color: var(--tableDarkBgColor);
  }
  .row[data-row='even'] {
    background-color: var(--tableLightBgColor);
  }
  .row[data-type='display'] {
    padding-top: 0.815rem;
    padding-bottom: 0.815rem;
  }
  .row[data-type='input'] {
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
  }
  .row[data-type='slider'] {
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
  }
  .field {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    width: 6rem;
  }
  .field[data-field='wide'] {
    width: 10rem;
  }
  .field[data-field='slider'] {
    width: 9rem;
    display: flex;
    flex-direction: column;
    gap: 0.25rem;

    .lastYearLabel {
      font-size: 0.9rem;
      padding-top: 0.25rem;
      opacity: 0.9;
    }
  }
  .field[data-field='display'] {
    padding-left: 15px;
  }

  .headerLabel {
    text-align: center;
    font-family: 'Poppins';
    font-size: 1rem;
    font-weight: 400;
    color: var(--tableTextColor);
  }
  .rowLabel {
    font-family: 'Poppins';
    font-size: 1rem;
    font-weight: 400;
    color: var(--tableTextColor);
    &[data-type="tours"] {
      font-size: 1.1rem;
      font-weight: 500;
      margin-bottom: 0.25rem;
    }
  }
  .rowLabel[data-fontsize='base'] {
    font-size: 1rem;
  }
  .rowLabel[data-fontsize='small'] {
    font-size: 15px;
  }
  .rowLabel[data-fontsize='smaller'] {
    font-size: 13px;
  }

  .displayLabel {
    text-align: center;
  }
  .formItem {
    margin-bottom: 0 !important;
    color: var(--labelTextColor);
  }
  .sliderWrapper {
    width: 15rem;
    transform: translateY(0.75rem);
    margin: -0.75rem 0.75rem 0 2rem;
    // margin-top: -0.75rem;
    margin-right: 0.75rem;
  }

  .btnWrapper {
    display: flex;
    margin: auto 0;
    justify-content: flex-start;
  }

  .cancelBtn {
    margin-right: 1rem;
    width: 8rem;
    // background-color: var(--labelTextColor);
    // border: 1px solid var(--labelTextColor);
    text-transform: uppercase;
  }
  .submitBtn {
    text-transform: uppercase;
    background-color: #4361f9;
    width: 8rem;

    &:hover {
      background-color: #2c3e9e !important;
    }
  }

  .lastYearToursRow {
    display: flex;
    width: 100%;
    padding: 0.75rem;
    background-color: rgba(67, 97, 249, 0.03);
    border-radius: 6px;
    border: 1px solid rgba(67, 97, 249, 0.1);

    .field {
      display: flex;
      justify-content: center;
    }
  }

  .toursInputRow {
    display: flex;
    width: 100%;
    align-items: baseline;
    padding: 0.25rem 0;
  }

  .lastYearLabel {
    font-family: 'Poppins';
    font-size: 1.1rem;
    font-weight: 500;
    color: #4361f9;
    opacity: 0.8;
    padding-left: 0;
    text-align: left;
  }

  .lastYearValue {
    font-family: 'Poppins';
    font-size: 0.9rem;
    font-weight: 500;
    color: #4361f9;
    text-align: center;
    padding: 0.25rem 0.5rem;
    background-color: rgba(67, 97, 249, 0.08);
    border-radius: 4px;
    min-width: 2.5rem;
  }
}
