.container {
  padding: 20px;
  .section {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
    .card {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      background: #d8d4d4;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
      border-radius: 40px;
      width: 340px;
      height: 190px;
      img {
        width: 40px;
      }
    }
  }
}
