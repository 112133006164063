.chartContainer {
  height: 400px;
  max-height: 600px;
  overflow-x: hidden;
}

.customTooltip {
  padding: 10px;

  .para {
    max-width: 300px;
    font-size: small;
  }
}
