.statWrapper {
  position: relative;
}
.statLoader {
  position: absolute;
  top: 1rem;
  left: 50%;
}

.statGprCard {
  --cardWidth: 248px;
  --cardHeight: 400px;
  width: var(--cardWidth);
  height: var(--cardHeight);
  position: relative;
  padding: 1rem;
  color: rgba(0, 0, 0, 0.88);
  background: #fff;
  border-radius: 0.5rem;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.03),
    0 1px 6px -1px rgba(0, 0, 0, 0.02), 0 2px 4px 0 rgba(0, 0, 0, 0.02);
}

.statGprCardTitle {
  position: absolute;
  top: 1rem;
  font-family: 'Poppins';
  font-size: 1.1rem;
  font-weight: 600;
  color: #2c313a;
}
.statTitleWrapper {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
}
.statTitle {
  padding-left: 1.5rem;
  font-family: 'Poppins';
  font-size: 1.1rem;
  font-weight: 600;
  color: #2c313a;
}

.statItemWrapper {
  width: calc(var(--cardWidth) - 2rem);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  margin-bottom: 1rem;
  padding: 1.4rem 1rem 1.5rem;
  font-family: 'Poppins';
  font-size: 1.2rem;
  font-weight: 500;
  border: 1px solid #e3e3e3;
  border-radius: 0.5rem;
}
.statItemName {
  font-family: 'Poppins';
  font-size: 1.25rem;
  font-weight: 400;
}
.statItemValue {
  font-family: 'Poppins';
  font-size: 1.875rem;
  font-weight: 600;
}
.gprBudget {
  --indigo500: #4361f9;
  color: var(--indigo500);
}
.gprActual {
  --orange500: #ffb84c;
  color: var(--orange500);
}
.positiveVariance {
  color: #70db70;
}
.negativeVariance {
  color: #ff5f5f;
}

.statOccupancyCard {
  --cardWidth: 583px;
  --cardHeight: 400px;
  width: var(--cardWidth);
  height: var(--cardHeight);
  padding: 1.5rem 0 0.5rem;
  color: rgba(0, 0, 0, 0.88);
  background: #fff;
  border-radius: 0.5rem;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.03),
    0 1px 6px -1px rgba(0, 0, 0, 0.02), 0 2px 4px 0 rgba(0, 0, 0, 0.02);
}

@media screen and (min-width: 1192px) {
  .statOccupancyCard {
    --cardWidth: 653px;
  }
}
@media screen and (min-width: 1300px) {
  .statOccupancyCard {
    --cardWidth: 753px;
  }
}
@media screen and (min-width: 1400px) {
  .statOccupancyCard {
    --cardWidth: 853px;
  }
}
@media screen and (min-width: 1544px) {
  .statOccupancyCard {
    --cardWidth: 996px;
  }
}

.statCard {
  --cardWidth: 233px;
  --cardHeight: 300px;
  position: relative;
  width: var(--cardWidth);
  height: var(--cardHeight);
  padding: 1rem;
  color: rgba(0, 0, 0, 0.88);
  background: #fff;
  border-radius: 0.5rem;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.03),
    0 1px 6px -1px rgba(0, 0, 0, 0.02), 0 2px 4px 0 rgba(0, 0, 0, 0.02);
}
.statCardTitle {
  font-family: 'Poppins';
  font-size: 1.25rem;
  font-weight: 500;
  color: #2c313a;
  display: flex;
  align-items: center;
  gap: 8px;
}
.statCardSection {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(var(--cardHeight) * 0.2);
  padding: 0.8rem 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.03),
    0 1px 6px -1px rgba(0, 0, 0, 0.02), 0 2px 4px 0 rgba(0, 0, 0, 0.02);
}
.statCardTopSection {
  --indigo100: #eceffe;
  --indigo500: #4361f9;
  color: var(--indigo500);
  background-color: var(--indigo100);
  border-radius: 0.5rem;
  margin-top: 4.5rem;
}
.statCardBottomSection {
  --orange100: #fff8ed;
  --orange500: #ffb84c;
  color: var(--orange500);
  background-color: var(--orange100);
  border-radius: 0.5rem;
  margin-top: 1rem;
}
.statCardTopSection label,
.statCardBottomSection label {
  --grey700: #8390a2;
  color: var(--grey700);
  font-family: 'Poppins';
  font-size: 1rem;
  font-weight: 400;
}
.statCardValue {
  font-family: 'Poppins';
  font-size: 1.875rem;
  font-weight: 600;
}

.statBarChart {
  position: absolute;
  top: 3.2rem;
  left: 0.25rem;
  width: 245px;
}
.statBarChartLabel {
  position: absolute;
  top: 0.4rem;
  left: 1rem;
  width: 192px;
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
  font-family: 'Poppins';
  font-size: 0.875rem;
  font-weight: 400;
  color: #8a8e93;
}

.columnChart {
  --chartWidth: 200px;

  .apexcharts-column-chart-1 {
    width: var(--chartWidth) !important;
  }

  svg {
    width: var(--chartWidth) !important;
  }
}

.occupancyTooltip {
  color: #fff;
  font-size: 0.75rem;
  font-weight: 400;
  padding: 0.5rem;
  text-transform: capitalize;
  text-align: center;

  &[data-type='budget'] {
    --indigo500: #4361f9;
    background: var(--indigo500);
    border: 1px solid var(--indigo500);
  }

  &[data-type='actual'] {
    --orange500: #ffb84c;
    background: var(--orange500);
    border: 1px solid var(--orange500);
  }

  &[data-type='occupancy'] {
    --purple300: #a872fc;
    background: var(--purple300);
    border: 1px solid var(--purple300);
  }
}

.infoIcon {
  color: #8390a2;
  font-size: 16px;
  cursor: pointer;
  transition: color 0.3s;
  
  &:hover {
    color: #4361f9;
  }
}
