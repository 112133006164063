.errCard {
  --cardWidth: 233px;
  --cardHeight: 100%;
  position: relative;
  width: var(--cardWidth);
  height: var(--cardHeight);
  //   padding: 1rem;
  color: #616b79;
  background: #fff;
  border-radius: 0.5rem;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.03),
    0 1px 6px -1px rgba(0, 0, 0, 0.02), 0 2px 4px 0 rgba(0, 0, 0, 0.02);
}

.errCardFooter {
  height: 1.4rem;
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
  background-color: #cfd4db;
}
