@use './utils/styles/global' as global;
@use './utils/styles/variables' as variables;
@use './utils/styles/texts' as *;
@use './utils/styles/fonts';

#root {
  // overflow-y: hidden;
  height: 100vh;
}

body {
  font-family: "Poppins";
}

::-webkit-scrollbar {
  height: 4px;
  width: 4px;
  // background: gray;
  visibility: hidden;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

::-webkit-scrollbar-thumb:horizontal {
  // background: #000;
  border-radius: 10px;
}

.ant-table-sticky-scroll {
  display: none !important;
}

.ant-table-header.ant-table-sticky-holder {
  top: -20px !important;
}

.ant-table-wrapper .ant-table-row:nth-child(even) .ant-table-cell-fix-left {
  background: #eff1f0 !important;
}
// this may be removed. This did not exist in submarket snapshot branch, we merged into staging and had this conflict. These only existed in staging.
.ant-table-wrapper .ant-table {
  scrollbar-color: unset;
}

span.ant-select-selection-item {
  color: #8390a2;
  font-weight: 300 !important;
}
