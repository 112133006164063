.container {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  .firstSection {
    width: 75%;
    .inputs {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      background: #ffffff;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
      border-radius: 15px;
      padding: 10px 25px;
      align-items: center;
      justify-content: space-between;
      &>div select ~ select{
        margin-left: 2rem;
      }
      select {
        width: 10rem;
        background: #d9d9d9;
        border-radius: 10px;
        padding: 5px 3px;
        margin: 0.5rem;
        border: none;
        border-right: 5px solid transparent;
        font-family: "Outfit";
        font-weight: 700;
      }
      input[type="checkbox"] {
        accent-color: #d9d9d9;
        -webkit-box-shadow: 0px 0px 0px 2px rgba(0, 0, 0, 1);
        -moz-box-shadow: 0px 0px 0px 2px rgba(0, 0, 0, 1);
        box-shadow: 0px 0px 0px 2px rgba(0, 0, 0, 1);
        margin-right: 10px;
        width: 14px;
        height: 14px;
      }
      div div div {
        width: 10px;
        height: 10px;
        background: #d9d9d9;
        border: 2px solid black;
        margin-right: 10px;
        display: inline-block;
      }
      label {
        font-family: "Outfit";
        font-weight: 700;
      }
    }
    .chart {
      background: #ffffff;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
      border-radius: 15px;
      margin-top: 1rem;
    }
   
  }
  .secondSection {
    width: 35%;
    .properties {
      background: #ffffff;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
      border-radius: 15px;
      padding: 2rem;
      width: 70%;
      input[type="checkbox"]{
        height: 14px;
        width: 14px;
        margin: 0;
        margin-right: 10px;
      }
      div div div {
        width: 10px;
        height: 10px;
        background: #d9d9d9;
        border: 2px solid black;
        margin-right: 10px;
        display: inline-block;
      }
      label {
        font-family: "Outfit";
        font-weight: 700;
      }
    }
  }
}

// .container {
//   width: 80%;
//   margin: 0 auto;
//   .inputs {
//     display: flex;
//     align-items: center;
//     flex-wrap: wrap;
//     select {
//       width: 15rem;
//       width: 80%;
//       background: #d9d9d9;
//       border-radius: 10px;
//       padding: 10px 3px;
//       margin: 0.5rem;
//       margin: 0 auto;
//       border: none;
//       border-right: 5px solid transparent;
//       font-family: "Outfit";
//       font-weight: 700;
//     }
//   }

//   .section {
//     padding-top: 40px;
//     display: flex;
//     flex-direction: column;
//     .axis {
//       display: flex;
//       justify-content: center;
//       margin-top: 10px;
//       margin-bottom: 4px;
//       gap: 10px;
//       p {
//         font-weight: 600;
//         font-style: oblique;
//       }
//     }
//     .chart {
//       display: flex;
//       justify-content: center;
//       .chart_content {
//         // "md:w-8/12 sm:w-[500px] w-[375px]"
//         width: 70%;
//       }
//     }
//   }
// }
