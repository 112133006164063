.header {
  font-family: 'Outfit';
  background-color: #ffffff;
  height: 54px;
  width: 100%;
  display: flex;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  justify-content: flex-start;

  @media screen and (max-width: 767px) {
    // justify-content: flex-end;
    width: 100%;
    margin-top: 54px;
  }
}

.tabs {
  display: flex;
  flex-wrap: nowrap; /* Ensure tabs stay in a single line */
  overflow-x: auto; /* Allow horizontal scrolling */
  white-space: nowrap; /* Prevents wrapping of tab items */
  padding-left: 1rem;
  gap: 2rem; /* Ensure padding is only applied once */

  @media screen and (max-width: 767px) {
    padding-right: 1rem;
    gap: 1rem;
  }

  @media screen and (min-width: 767px) and (max-width: 1024px) {
    gap: 1rem;
  }
}

.tabList {
  color: #adadad;
  padding: 0px 7px;
  border-bottom: 2px solid transparent;
  cursor: pointer;
  transition: all 0.25s ease;
  display: inline-block; /* Ensure each tab is inline */
  white-space: nowrap; /* Prevent text wrapping */

  &.active {
    color: #3aa4f6;
    border-bottom: 2px solid #3aa4f6;
    @media screen and (max-width: 767px) {
      border-top: 2px solid #3aa4f6;
      border-bottom: none;
    }
  }

  &:hover {
    p {
      color: #3aa4f6;
    }
  }
}

/* Optional: Customize scrollbar appearance for better UI */
.header::-webkit-scrollbar {
  width: 1px !important;
}

.header::-webkit-scrollbar-thumb {
  background-color: #007bff;
  border-radius: 4px;
}
